@keyframes fade {
  from {
  opacity: 0;
  }
  to {
  opacity: 1;
  }
  }
  
  
  body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  }
  
  .footer {
  background-color: transparent;
  padding: 12px;
  display: flex;
  justify-content: center;
  margin-top: auto;
  font-family: "Questrial";
  font-weight: 600;
  font-size: 2.5vh;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid;
  border-color: #000000;
  animation: fade ease 1.5s forwards;
  }
  
  .footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  }

  .footer a {
    font-size: 2.5vh;
    color: #000000;
    text-decoration: none;
    }

  .footer a:hover {
    color: rgb(239, 121, 255);
    transform: scale(1.6);
    transition: color 0.18s ease-in-out;
    }
  
  .footer li {
  margin: 0 10px;
  }
  
  .footer a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  }
