.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


body {
  background-color: #ffffff;
  color: #202020;
  font-family: Arial;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


html,body {
  max-width: 100%;
  overflow-x: hidden;
}
