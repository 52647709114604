@keyframes fade {
  from {
  opacity: 0;
  }
  to {
  opacity: 1;
  }
  }

.photo-carousel2 {
  height: 1400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  margin:auto;
  animation: fade ease 2s forwards;
}

.photo-carousel2 img {
  align-items: center;
  justify-content: center;
  text-align: center;
  object-fit: cover;
  object-position: center;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

@media (max-width: 800px) {
  .photo-carousel2 {
    width: 100%;
    height: 650px;
    padding-top: 50px;
    padding-bottom: 10vh;
    animation: fade ease 2s forwards;
  }
  .photo-carousel2 img {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    object-fit: cover;
    object-position: center;
  }
}