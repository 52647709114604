@import url('https://fonts.googleapis.com/css?family=Marcellus');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300');

@keyframes fade {
  from {
  opacity: 0;
  }
  to {
  opacity: 1;
  }
  }
  
  .parent {
  text-align: right;
  overflow: hidden;
  }
  
  .navbar {
    display: inline-block;
  position: fixed;
  z-index: 3;
  height: 35px;
  top: 0;
  right: 0;
  line-height: 40px;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-family: "Questrial";
  font-weight: 600;
  animation: fade ease 2s forwards;
  transition: all 0.1s ease-in-out, opacity 0.1s ease-in-out;
  transform: scale(1);
  opacity: 0.7;
  }
  
  .navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  }
  
  .navbar li {
  margin: 0 10px;
  }
  
  .navbar a {
  font-size: 2.5vh;
  color: #000000;
  text-decoration: none;
  }
  
  
  .navbar a:hover {
  color: rgb(239, 121, 255);
  transform: scale(1.6);
  transition: color 0.18s ease-in-out;
  }


@media (max-width: 1160px) {
  .parent {
    text-align: center;
    overflow: hidden;
    position: relative;
    }
  .navbar {
    position: sticky;
    border-top:  1px solid;
    border-color: black;
  }
}