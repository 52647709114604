@import url('https://fonts.googleapis.com/css2?family=Questrial');


@keyframes fade {
  from {
  opacity: 0;
  }
  to {
  opacity: 1;
  }
  }

  .full {
    animation: fade ease 1s forwards;
  }

  .homeContainer1 {
    margin: 5%;
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
  .galleryTitle1 {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 2px solid;
    margin-top: 2%;
    margin-bottom: 2%;
    line-height: 50px;
    font-size: 45px;
    animation: fade ease 1s forwards;
    font-family: "Questrial";
  }

  .homeContainer2 {
    margin: 5%;
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
  .galleryTitle2 {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 2px solid;
    margin-top: 2%;
    margin-bottom: 2%;
    line-height: 50px;
    font-size: 45px;
    animation: fade ease 1s forwards;
    font-family: "Questrial";
  }

  .galleryBody2 {
    left: 100;
    bottom: 0;
    font-size: 20px;
    animation: fade ease 1s forwards;
    font-family: "Questrial";
    padding: 0;
    color: rgb(85, 85, 85);
  }

  .galleryBody1 {
    left: 100;
    bottom: 0;
    font-size: 20px;
    animation: fade ease 1s forwards;
    font-family: "Questrial";
    padding: 0;
    color: rgb(85, 85, 85);
  }

  .galleryBody1 a {
    text-decoration: none;
    color: rgb(145, 145, 145);
    }

    .galleryBody1 a:hover {
      color: rgb(239, 121, 255);
      transform: scale(1.6);
      transition: color 0.18s ease-in-out;
      }


