@import url('https://fonts.googleapis.com/css?family=Quicksand:wght@400');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@400');
@import url('https://fonts.googleapis.com/css2?family=Questrial');
@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@400');
@import url('https://fonts.googleapis.com/css2?family=Hina+Mincho:wght@400');

@keyframes fade {
  from {
  opacity: 0;
  }
  to {
  opacity: 1;
  }
  }

.bg {
  animation: fade ease 1.5s forwards;
  background-color: #ff1495;
  position:fixed;
  padding:0;
  margin:0;
  z-index: -1000000;
  left:0;
  top:0;
  width: 200%;
  height: 200%;
}

.body-text {
  padding-top: 10px;
  animation: fade ease 1.5s forwards;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}

p {
  color: rgb(0, 0, 0);
  font-size: 4vh;
  padding-top: 75px;
  font-weight: 100;
  padding-bottom: 70px;
  padding-left: 10%;
  padding-right: 10%;
  font-family: "Questrial";
  font-weight: 600;
  text-align: left;
  background-color: transparent;
  animation: fade ease 0.8s forwards;
  opacity: 0.7;
}

.body-text ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  }
  
  .body-text li {
  margin: 0 10px;
  }
  
  .body-text a {
  font-size: 5vh;
  text-decoration: none;
  }

  @media (max-width: 800px) {
    p {
      color: rgb(0, 0, 0);
      font-size: 3vh;
      padding-top: 5px;
      padding-bottom: 35px;
      padding-left: 6%;
      padding-right: 6%;
    }
  }