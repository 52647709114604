@import url('https://fonts.googleapis.com/css?family=Quicksand:wght@400');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:wght@400');
@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@600');
@import url('https://fonts.googleapis.com/css2?family=Questrial:wght@900');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800');

@keyframes fade {
  from {
  opacity: 0;
  }
  to {
  opacity: 1;
  }
  }
   
  .header {
    display:grid;
    height: 45px;
    position: fixed;
    z-index: 3;
    line-height: 50px;
    /*border: 2px solid;*/
    border-image-slice: 1;
    margin-left: 0px;
    left: 0;
    top:-15px;
    justify-content: center;
    line-height: 1.2;
    text-align: center;
    background-color: transparent;
    font-family: "Montserrat";
    font-weight: 800;
    animation: fade ease 1.5s forwards;
    transition: all 0.1s ease-in-out, opacity 0.1s ease-in-out;
    transform: scale(1);
    opacity: 0.7;
    }
    
    .header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    }
    
    .header li {
    margin: 0 10px;
    }
    
    .header a {
    font-size: 12vh;
    color: #000000;
    text-decoration: none;
    }
    
    @media (max-width: 1160px) {
      .header {
      margin-top: 30px;
      height:10vw;
      left:0;
      margin-bottom: auto;
      margin-right:0px;
      margin-left:0px;
      position: relative;
      }
      .header a {
        font-size:11vw;
        color: #000000;
        text-decoration: none;
        }
    }